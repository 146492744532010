import React from 'react';

import css from './style.module.scss';

export const Buttons = ({ children, style }) => (
  <div className={css.buttons} style={style}>
    {children}
  </div>
);

export default Buttons;
