import React from 'react'
import Button from 'components/Button'
import Section from 'components/ui/Section'
import Buttons from 'components/ui/Buttons'
import Images from 'components/ui/Images'
import HeroBar from 'components/ui/HeroBar'
import Page from 'components/Page'

const IndexPage = ({ handleSignIn }) => (
  <Page withHeader={true} withBack={false} homepage>
    <Section
      upTitle="Habit Tracker for everyone"
      title="The path to a new you."
    >
      <b>Atoms</b> helps you form new habits to achieve your goals.
      <br />
    </Section>
    <Buttons style={{ marginTop: '-32px', marginBottom: '64px' }}>
      <Button style={{ marginRight: '32px' }} onClick={handleSignIn}>
        Sign in with Blockstack
      </Button>
    </Buttons>

    <Images paths={['homepage.1.png']} />
    {/* 
    <Section title="All your articles are securely saved and encrypted on Blockstack 🔒">
      Cozy uses <b>BlockStack</b> to (forever!) save all the articles you want
      to read.
      <br />
      Which means that yes, even if the website goes down or the original
      articles are deleted, you will have access to the articles!
    </Section>

    <Section title="Find your reading style 👓">
      Everyone has a preferred reading setup.
      <br />
      Our article view is completely customizable to help you find yours.
    </Section>

    <Images paths={['style.1.png', 'style.2.png', 'style.3.png']} />

    <Section title="Nobody can see inside your library. Only you.">
      Thanks to BlockStack, all data inside Cozy are encrypted by default and
      accessible only by you. Nobody will see what are you reading and sell that
      data. 🙅‍♀️
    </Section>

    <Section title="Use our extensions to add articles from any page">
      Install our Chrome or Firefox extension to save an article in a zap ⚡
    </Section>

    <Buttons style={{ marginTop: '-32px', marginBottom: '64px' }}>
      <Button style={{ marginRight: '16px' }} onClick={handleSignIn}>
        Connect now to Blockstack
      </Button>
     
      <Button
        secondary
        style={{ marginRight: '16px' }}
        to="https://addons.mozilla.org/en-US/firefox/addon/cozy-reader/"
      >
        Firefox extension
      </Button>
    </Buttons> */}
    <div
      style={{
        marginTop: '128px',
        marginBottom: '128px',
        paddingTop: '64px',
        borderTop: '2px solid var(--background-color-alt)',
      }}
    >
      <p>
        Made by <a href="https://francesco.space">Francesco</a>.
      </p>
    </div>
  </Page>
)

export default IndexPage
