import React from 'react'
import { SectionTitle } from 'components/ui/Typography'
import Button from 'components/ui/Button'
import { Link, withPrefix } from 'gatsby'

import css from './style.module.scss'

export const Images = ({ paths }) => (
  <div className={css.images}>
    <div className={css.imagesRow}>
      {paths.map(i => (
        <img
          key={i}
          loading="lazy"
          src={withPrefix(`/homepage/${i}`)}
          alt={i}
          className={css.image}
        />
      ))}
      <div className={css.space} />
    </div>
  </div>
)

export default Images
